<template>
  <div id="content">
    <div id="loading" v-if="loading">
      <div class="dot-flashing"></div>
    </div>
    <div class="wrapper">
      <header>
        <h1>{{ $t('message.contact_support') }}</h1>
      </header>
      <p>
        <v-alert
          outlined
          type="warning"
          border="left"
        >
          <strong>
            {{ $t('message.connection_issue_instructions') }}
          </strong>
        </v-alert>
        <br>{{ $t('message.new_access_requests') }}
      </p>
      <ul>
        <li> {{ $t('message.delegation_rights_option_1') }}</li>
        <li>{{ $t('message.delegation_rights_option_2') }}</li>
        <li>{{ $t('message.delegation_rights_option_3') }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
import DefaultLayout from '../../layouts/DefaultLayout.vue'
import config from '../../config'

export default {
  name: 'Contact',
  data: () => ({
    valid: false,
    errors: [],
    loading: false
  }),
  created () {
    this.$emit('update:layout', DefaultLayout)
    document.title = config.title + ' - Contact'
  }
}
</script>
